import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Chart, Settings, LIGHT_THEME, Partition, PartitionLayout, ShapeTreeNode } from '@elastic/charts'
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiPanel, EuiSpacer, EuiStat, EuiTitle, EuiSuperSelect, EuiPageHeader, EuiPageHeaderSection, EuiPage, EuiPageBody, EuiFilterGroup, EuiPopover, EuiFilterButton, EuiFilterSelectItem, EuiDatePickerRange, EuiDatePicker, EuiFlexGrid, EuiHideFor, EuiShowFor, EuiCallOut } from '@elastic/eui'

import { Analytics, DashboardCreative } from 'api/interfaces'
import { useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { useGetDashboardAnalyticsQuery, useGetDashboardFiltersQuery, usePostTouchDashboardMutation } from 'api/rtkQueryApi/platform/dashboardApi'
import { setIsNewlyCreatedState, updateShowWelcome } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import MobileDashboardPage from 'features/mobileDashboard/MobileDashboardPage'
import { ShopifyLandingPanel } from 'features/shopify/ShopifyLandingPanel'
import history from 'services/HistoryService'
import { IAcDictionary } from 'utils/AcDictionary'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import BillboardOverviewChart from './BillboardOverviewChart'
import InternetOverviewChart from './InternetOverviewChart'
import TvOverviewChart from './TvOverviewChart'
import { BillboardLegend } from './legends/BillboardLegend'
import { TvLegend } from './legends/TvLegend'

const DashboardPage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentOrganization, currentAccount, isShopifyUser, showWelcome: showWelcomeState } = useSelector((state: RootState) => state.app)
  const [totalImpressions, setTotalImpressions] = useState<number>(0)
  const [totalClicks, setTotalClicks] = useState<number>(0)
  const [totalTvImpressions, setTotalTvImpressions] = useState<number>(0)
  const [totalBillboardImpressions, setTotalBillboardImpressions] = useState<number>(0)
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment().add(-1, 'd'))
  const [isAdsPopoverOpen, setIsAdsPopoverOpen] = useState(false)
  const [isCampaignsPopoverOpen, setIsCampaignsPopoverOpen] = useState(false)
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([])
  const [availableAds, setAvailableAds] = useState<DashboardCreative[]>([])
  const [selectedAds, setSelectedAds] = useState<string[]>([])
  const [label, setLabel] = useState('last_7')
  const [hidden, setHidden] = useState(false)
  const [billboardBizToColorMap, setBillboardBizToColorMap] = useState<IAcDictionary>({})
  const [tvBizToColorMap, setTvBizToColorMap] = useState<IAcDictionary>({})
  const formatter = new Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 2 })
  const whiteLabel = useWhiteLabel()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const showWelcomeQuery = query.get('showWelcome')?.toLowerCase() === 'true'
  const showWelcome = isShopifyUser && showWelcomeQuery && showWelcomeState

  const { isLoading: filtersIsLoading, isFetching: filtersIsFetching, data: filters } = useGetDashboardFiltersQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() ?? false })

  const dashboardAnalyticsQuery = {
    accountId: currentAccount?.id ?? '',
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
    campaignIds: selectedCampaigns,
    adIds: selectedCampaigns.length === 1 ? selectedAds : filters?.campaigns?.flatMap(c => c.creativeIds) ?? [],
    label: label,
    isAgency: whiteLabel?.isAgencies() ?? false
  }

  const {
    isLoading: dashboardIsLoading,
    isFetching: dashboardIsFetching,
    refetch: refetchDashboard,
    data: dashboard
  } = useGetDashboardAnalyticsQuery(dashboardAnalyticsQuery, {
    skip: !filters
  })

  const { data: canSeeBundleInsights } = useGetCanSeeBundleInsightsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount || !currentOrganization })

  useEffect(() => {
    if (canSeeBundleInsights) {
      history.push('/planInsights')
    }
  }, [canSeeBundleInsights])

  useEffect(() => {
    if (currentAccount) {
      setSelectedCampaigns([])
      setSelectedAds([])
      setTotalImpressions(0)
      setTotalClicks(0)
      setTotalTvImpressions(0)
      setTotalBillboardImpressions(0)
    }
  }, [currentAccount, dispatch])

  useEffect(() => {
    if (filtersIsFetching || !filters) {
      return
    }

    setSelectedCampaigns(filters?.campaigns?.map(c => c.id))
  }, [filters])

  useEffect(() => {
    if (filtersIsFetching || !filters) {
      return
    }

    let available: Array<DashboardCreative> = []
    selectedCampaigns.forEach(cId => {
      const campaign = filters.campaigns.find(c => c.id === cId)
      if (campaign) {
        let m = filters.creatives.filter(cr => campaign.creativeIds.includes(cr.id) && available.filter(ad => cr.id === ad.id).length === 0)
        available = available.concat(m)
      }
    })

    setAvailableAds(available)
    setSelectedAds(available.map(ad => ad.id))
  }, [filters, selectedCampaigns])

  useEffect(() => {
    if (!dashboardIsFetching && dashboard) {
      const impressions = dashboard.internetCampaigns
        .map(a => a.impressions)
        .reduce(function (a, b) {
          return a + b
        }, 0)
      const clicks = dashboard.internetCampaigns
        .map(a => a.clicks)
        .reduce(function (a, b) {
          return a + b
        }, 0)
      const tvImpressions = dashboard.tvCampaigns
        .filter(a => !a.dimension)
        .map(a => a.impressions)
        .reduce(function (a, b) {
          return a + b
        }, 0)
      const billboardImpressions = dashboard.billboardCampaigns
        .filter(a => !a.dimension)
        .map(a => a.impressions)
        .reduce(function (a, b) {
          return a + b
        }, 0)
      setTotalImpressions(impressions)
      setTotalClicks(clicks)
      setTotalTvImpressions(tvImpressions)
      setTotalBillboardImpressions(billboardImpressions)
    }
  }, [dashboard])

  const onUpdateButtonClick = () => {
    refetchDashboard()
  }

  const onCampaignsButtonClick = () => {
    setIsCampaignsPopoverOpen(!isCampaignsPopoverOpen)
  }

  const closeCampaignsPopover = () => {
    setIsCampaignsPopoverOpen(false)
  }

  const onAdsButtonClick = () => {
    setIsAdsPopoverOpen(!isAdsPopoverOpen)
  }

  const closeAdsPopover = () => {
    setIsAdsPopoverOpen(false)
  }

  const uncheckAll = () => {
    setHidden(!hidden)
    setSelectedCampaigns([])
  }

  const checkAll = () => {
    setHidden(!hidden)
    let campaignIdArray = filters?.campaigns?.map(i => i.id) ?? []
    setSelectedCampaigns(campaignIdArray)
  }

  const toggleSelectedCampaign = (id: string) => {
    const filtersCampaigns = filters?.campaigns ?? []
    const selectedGroupIndex = selectedCampaigns.indexOf(id)
    const updatedSelectedGroups = [...selectedCampaigns]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }

    if (selectedCampaigns.length > 0 && updatedSelectedGroups.length <= 0) {
      setHidden(true)
    }

    if (selectedCampaigns.length < filtersCampaigns.length && updatedSelectedGroups.length >= filtersCampaigns.length) {
      setHidden(false)
    }

    setSelectedCampaigns(updatedSelectedGroups)
  }

  const toggleSelectedAd = (id: string) => {
    const selectedGroupIndex = selectedAds.indexOf(id)
    const updatedSelectedGroups = [...selectedAds]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }
    setSelectedAds(updatedSelectedGroups)
  }

  const dateRanges = [
    {
      value: 'last_3',
      inputDisplay: 'Last 3 Days'
    },
    {
      value: 'last_7',
      inputDisplay: 'Last 7 Days'
    },
    {
      value: 'week',
      inputDisplay: 'This Week (so far)'
    },
    {
      value: 'last_30',
      inputDisplay: 'Last 30 Days'
    },
    {
      value: 'month',
      inputDisplay: 'This Month (so far)'
    },
    {
      value: 'last_month',
      inputDisplay: 'Last Month'
    },
    {
      value: 'last_90',
      inputDisplay: 'Last 90 Days'
    },
    {
      value: 'lifetime',
      inputDisplay: 'Lifetime'
    },
    {
      value: 'custom',
      inputDisplay: 'Custom'
    }
  ]

  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[1].value)
  const onDateRangeChange = (value: string) => {
    let today = moment()
    let yesterday = moment().add(-1, 'd')
    switch (value) {
      case 'last_3':
        setStart(today.add(-3, 'd'))
        setEnd(yesterday)
        setLabel('last_3')
        break

      case 'last_7':
        setStart(today.add(-7, 'd'))
        setEnd(yesterday)
        setLabel('last_7')
        break

      case 'week':
        setStart(today.startOf('week'))
        setEnd(yesterday)
        setLabel('week')
        break

      case 'last_30':
        setStart(today.add(-31, 'd'))
        setEnd(yesterday)
        setLabel('last_30')
        break

      case 'month':
        setStart(today.startOf('month'))
        setEnd(yesterday)
        setLabel('month')
        break

      case 'last_month':
        setStart(moment().subtract(1, 'months').startOf('month'))
        setEnd(moment().subtract(1, 'months').endOf('month'))
        setLabel('last_month')
        break

      case 'last_90':
        setStart(today.add(-91, 'd'))
        setEnd(yesterday)
        setLabel('last_90')
        break

      case 'lifetime':
        setStart(today.add(-10, 'years'))
        setEnd(yesterday)
        setLabel('lifetime')
        break
    }
    setSelectedDateRange(value)
  }

  const colors = ['#f49342', '#2B70F7', '#D36086', '#9170B8', '#54B399', '#AA6556', '#E7664C', '#D6BF57']

  const networkNames = (s: string | null) => {
    switch (s) {
      case 'News':
        return 'News Networks'
      case 'FamilyFriendly':
        return 'Family Friendly Networks'
      case 'Lifestyle':
        return 'Lifestyle Networks'
      case 'Entertainment':
        return 'Entertainment Networks'
      case 'Sports':
        return 'Sports Networks'
      default:
        return 'Major Networks'
    }
  }

  useEffect(() => {
    const sorted = dashboard?.billboardBreakdownCampaigns.map(d => d).sort((a, b) => (a.spend > b.spend ? -1 : 1)) ?? []
    const colorMap = { ...billboardBizToColorMap }
    sorted.forEach((s, i) => {
      colorMap[s.dimensionValue ?? ''] = colors[i]
    })
    setBillboardBizToColorMap(colorMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard])

  useEffect(() => {
    const sorted = dashboard?.tvBreakdownCampaigns.map(d => d).sort((a, b) => (a.spend > b.spend ? -1 : 1)) ?? []
    const colorMap = { ...tvBizToColorMap }
    sorted.forEach((s, i) => {
      colorMap[s.dimensionValue ?? ''] = colors[i]
    })
    setTvBizToColorMap(colorMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard])

  const onWelcomePanelGetStarted = (isDontShowAgainChecked: boolean) => {
    dispatch(setIsNewlyCreatedState(false))
    dispatch(updateShowWelcome(!isDontShowAgainChecked))
    history.push('/build')
  }

  const onWelcomePanelClose = () => {
    dispatch(setIsNewlyCreatedState(false))
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiShowFor sizes={['xs', 's']}>
          <MobileDashboardPage />
        </EuiShowFor>
        <EuiHideFor sizes={['xs', 's']}>
          <EuiPageBody>
            <EuiPageHeader>
              <EuiPageHeaderSection>
                <EuiTitle>
                  <h1>Dashboard</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
              <EuiPageHeaderSection>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFilterGroup>
                      {filters?.campaigns && filters?.campaigns?.length > 1 && (
                        <EuiPopover
                          ownFocus
                          button={
                            <EuiFilterButton id='campaignArrow' iconType='arrowDown' onClick={onCampaignsButtonClick} isSelected={isCampaignsPopoverOpen} numFilters={selectedCampaigns.length} hasActiveFilters={selectedCampaigns.length > 0} numActiveFilters={selectedCampaigns.length} isLoading={filtersIsLoading} isDisabled={filters?.campaigns?.length === 0} withNext={true}>
                              Campaigns
                            </EuiFilterButton>
                          }
                          isOpen={isCampaignsPopoverOpen}
                          closePopover={closeCampaignsPopover}
                          panelPaddingSize='none'>
                          <div className='euiFilterSelect__items'>
                            {!hidden ? (
                              <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={uncheckAll}>
                                Uncheck All
                              </EuiButton>
                            ) : (
                              <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={checkAll}>
                                Check All
                              </EuiButton>
                            )}
                            {filters.campaigns.map(item => (
                              <EuiFilterSelectItem checked={selectedCampaigns.includes(item.id) ? 'on' : undefined} key={`campaigns-${item.id}`} onClick={() => toggleSelectedCampaign(item.id)}>
                                {item.name}
                              </EuiFilterSelectItem>
                            ))}
                          </div>
                        </EuiPopover>
                      )}
                      {availableAds.length > 1 && selectedCampaigns.length === 1 && (
                        <EuiPopover
                          ownFocus
                          button={
                            <EuiFilterButton id='adsFilter' iconType='arrowDown' onClick={onAdsButtonClick} isSelected={isAdsPopoverOpen} numFilters={selectedAds.length} hasActiveFilters={selectedAds.length > 0} numActiveFilters={selectedAds.length} isLoading={filtersIsLoading} isDisabled={!filters?.creatives || filters.creatives.length === 0}>
                              Ads
                            </EuiFilterButton>
                          }
                          isOpen={isAdsPopoverOpen}
                          closePopover={closeAdsPopover}
                          panelPaddingSize='none'>
                          <div className='euiFilterSelect__items'>
                            {availableAds.map(item => (
                              <EuiFilterSelectItem checked={selectedAds.includes(item.id) ? 'on' : undefined} key={`ads-${item.id}`} onClick={() => toggleSelectedAd(item.id)}>
                                {item.name}
                              </EuiFilterSelectItem>
                            ))}
                          </div>
                        </EuiPopover>
                      )}
                      <EuiSuperSelect options={dateRanges} valueOfSelected={selectedDateRange} onChange={value => onDateRangeChange(value)} style={{ borderRadius: 0, minWidth: 200 }} />
                    </EuiFilterGroup>
                  </EuiFlexItem>
                  {selectedDateRange === 'custom' && (
                    <EuiFlexItem>
                      <EuiDatePickerRange
                        fullWidth={false}
                        startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
                        endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
                      />
                    </EuiFlexItem>
                  )}
                  <EuiFlexItem grow={false}>
                    <EuiButton id='update' fill={true} onClick={onUpdateButtonClick} iconType='refresh' isLoading={dashboardIsFetching} disabled={dashboardIsFetching}>
                      Update
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPageHeaderSection>
            </EuiPageHeader>

            {dashboardIsLoading && (
              <EuiFlexGroup justifyContent='spaceAround' alignItems='flexStart'>
                <EuiFlexItem grow={false}>
                  <EuiSpacer />
                  <EuiLoadingChart size='xl' />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}

            {dashboard && (
              <React.Fragment>
                <React.Fragment>
                  <EuiFlexGroup direction='row' style={{ flexGrow: 0 }}>
                    <EuiFlexItem grow={1}>
                      <EuiPanel>
                        <EuiTitle size='xs'>
                          <h4>Targeted TV Detail</h4>
                        </EuiTitle>
                        <EuiSpacer size='s' />
                        <div style={{ position: 'relative' }}>
                          <EuiStat style={{ position: 'absolute', top: '6rem', left: 0, width: '100%', height: '100%', zIndex: 1 }} textAlign={'center'} description={dateRanges.find(d => d.value === label)?.inputDisplay} title={formatter.format(totalTvImpressions)} reverse />
                          <Chart size={{ height: 230 }}>
                            <Settings baseTheme={LIGHT_THEME} showLegend={false} theme={{ partition: { linkLabel: { maximumSection: Infinity, maxCount: 0 }, outerSizeRatio: 1, emptySizeRatio: 0.6 } }} />
                            <Partition
                              data={dashboard.tvBreakdownCampaigns as any[]}
                              id='TvBreakdown'
                              valueAccessor={(a: Analytics) => a.spend}
                              valueFormatter={() => ''}
                              valueGetter='percent'
                              percentFormatter={(d: number) => `${Math.round((d + Number.EPSILON) * 100) / 100}%`}
                              layout={PartitionLayout.sunburst}
                              layers={[
                                {
                                  groupByRollup: (a: Analytics) => networkNames(a.dimensionValue),
                                  shape: { fillColor: (d: ShapeTreeNode) => colors[d.sortIndex] }
                                }
                              ]}
                            />
                          </Chart>
                        </div>
                      </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem grow={2}>
                      <TvOverviewChart showCreate={filters?.campaigns?.filter(c => c.type === 'TV').length === 0} tvCampaigns={dashboard.tvCampaigns} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiSpacer />

                  <TvLegend colorMap={tvBizToColorMap} />
                </React.Fragment>

                <EuiFlexGroup direction='row' style={{ flexGrow: 0 }}>
                  <EuiFlexItem grow={2}>
                    <InternetOverviewChart showCreate={filters?.campaigns?.filter(c => c.type === 'Internet').length === 0} internetCampaigns={dashboard.internetCampaigns} />
                  </EuiFlexItem>
                  <EuiFlexItem grow={1}>
                    <EuiHideFor sizes={['xs', 's']}>
                      <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                          <EuiPanel>
                            <EuiStat title={totalImpressions === 0 ? '--' : totalImpressions.toLocaleString()} description='Internet Views' titleSize='l' />
                          </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiPanel>
                            <EuiStat title={totalClicks === 0 ? '--' : totalClicks.toLocaleString()} description='Internet Clicks' titleSize='l' />
                          </EuiPanel>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiHideFor>
                    <EuiHideFor sizes={['m', 'l', 'xl']}>
                      <EuiFlexGrid columns={2}>
                        <EuiFlexItem>
                          <EuiPanel>
                            <EuiStat title={totalImpressions === 0 ? '--' : totalImpressions.toLocaleString()} description='Internet Views' titleSize='l' />
                          </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiPanel>
                            <EuiStat title={totalClicks === 0 ? '--' : totalClicks.toLocaleString()} description='Internet Clicks' titleSize='l' />
                          </EuiPanel>
                        </EuiFlexItem>
                      </EuiFlexGrid>
                    </EuiHideFor>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />

                <React.Fragment>
                  <EuiFlexGroup direction='row' style={{ flexGrow: 0 }}>
                    <EuiFlexItem grow={1}>
                      <EuiPanel>
                        <EuiTitle size='xs'>
                          <h4>Digital Billboard Detail</h4>
                        </EuiTitle>
                        <EuiSpacer size='s' />
                        <div style={{ position: 'relative' }}>
                          <EuiStat style={{ position: 'absolute', top: '6rem', left: 0, width: '100%', height: '100%', zIndex: 1 }} textAlign={'center'} description={dateRanges.find(d => d.value === label)?.inputDisplay} title={formatter.format(totalBillboardImpressions)} reverse />
                          <Chart size={{ height: 230 }}>
                            <Settings baseTheme={LIGHT_THEME} showLegend={false} theme={{ partition: { linkLabel: { maximumSection: Infinity, maxCount: 0 }, outerSizeRatio: 1, emptySizeRatio: 0.6 } }} />
                            <Partition
                              data={dashboard.billboardBreakdownCampaigns as any[]}
                              id='BillboardBreakdown'
                              valueAccessor={(a: Analytics) => a.spend}
                              valueFormatter={() => ''}
                              valueGetter='percent'
                              layout={PartitionLayout.sunburst}
                              percentFormatter={(d: number) => `${Math.round((d + Number.EPSILON) * 100) / 100}%`}
                              layers={[
                                {
                                  groupByRollup: (a: Analytics) => a.dimensionValue,
                                  shape: { fillColor: (d: ShapeTreeNode) => colors[d.sortIndex] }
                                }
                              ]}
                            />
                          </Chart>
                        </div>
                      </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem grow={2}>
                      <BillboardOverviewChart showCreate={filters?.campaigns?.filter(c => c.type === 'Billboard').length === 0} billboardCampaigns={dashboard.billboardCampaigns} />
                    </EuiFlexItem>
                  </EuiFlexGroup>

                  <BillboardLegend colorMap={billboardBizToColorMap} />
                </React.Fragment>
              </React.Fragment>
            )}
          </EuiPageBody>
        </EuiHideFor>
      </EuiPage>
      {showWelcome && <ShopifyLandingPanel onClose={onWelcomePanelClose} onGetStarted={onWelcomePanelGetStarted} />}
    </React.Fragment>
  )
}

export default DashboardPage
