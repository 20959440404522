import moment from 'moment/moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiDatePicker, EuiFieldNumber, EuiFormRow, EuiSpacer, EuiText } from '@elastic/eui'

import { Campaign, CampaignActiveStatus, CampaignStatus, CampaignType } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface CampaignBudget {
  budget: number
  start?: moment.Moment
  end?: moment.Moment
  method: BudgetMethod
}

export interface ICampaignBudgetSetter {
  campaign: Campaign
  onBudgetChange?: (value: CampaignBudget) => void
  campaignBudget: CampaignBudget
}

export enum BudgetMethod {
  Indefinite = 'indefinite',
  Dates = 'dates'
}

export const InternetMinWeeklyBudget: number = 70
export const InternetMinTotalBudget: number = 100
export const TvMinWeeklyBudget: number = 200
export const TvMinTotalBudget: number = 200
export const BbMinWeeklyBudget: number = 150
export const BbMinTotalBudget: number = 150

export const CampaignBudgetSetter: React.FC<ICampaignBudgetSetter> = props => {
  const whiteLabel = useWhiteLabel()
  const { currentAccount, isShopifyUser, isStaffUser } = useSelector((state: RootState) => state.app)

  const [minWeeklyBudget, setMinWeeklyBudget] = useState(InternetMinWeeklyBudget)
  const [minTotalBudget, setMinTotalBudget] = useState(InternetMinTotalBudget)
  const [minStartDate, setMinStartDate] = useState(moment())
  const [method, setMethod] = useState<BudgetMethod>(BudgetMethod.Indefinite)

  useEffect(() => {
    if (props.campaign.type === CampaignType.Internet) {
      setMinWeeklyBudget(InternetMinWeeklyBudget)
      setMinTotalBudget(InternetMinTotalBudget)
    } else if (props.campaign.type === CampaignType.TV) {
      setMinWeeklyBudget(TvMinWeeklyBudget)
      setMinTotalBudget(TvMinTotalBudget)
    } else if (props.campaign.type === CampaignType.Billboard) {
      setMinWeeklyBudget(BbMinWeeklyBudget)
      setMinTotalBudget(BbMinTotalBudget)
    }

    if (isStaffUser) {
      setMinStartDate(moment())
    } else if (props.campaign?.activated) {
      setMinStartDate(moment(props.campaign?.activated).add(3, 'day'))
    } else {
      setMinStartDate(moment().add(3, 'day'))
    }

    setMethod(whiteLabel?.isAgencies() && props.campaign.startDate ? BudgetMethod.Dates : BudgetMethod.Indefinite)
  }, [props.campaign, whiteLabel, isStaffUser])

  const getBudgetLabel = useCallback(() => {
    if (isShopifyUser) {
      return `30 Day Budget`
    }

    if (props.campaign?.hasBundle) {
      return 'Campaign Budget'
    }

    return `${currentAccount!.billingFrequency} Budget ($${minWeeklyBudget} minimum)`
  }, [currentAccount, props.campaign, isShopifyUser, minWeeklyBudget])

  const onBudgetChange = (value: number) => {
    if (props.onBudgetChange) {
      const newBudget = {
        ...props.campaignBudget,
        budget: value
      }
      props.onBudgetChange(newBudget)
    }
  }

  const onStartChange = (value: moment.Moment) => {
    if (props.onBudgetChange) {
      const momentValue = moment.utc(value.format('MM/DD/yyyy'))
      const newBudget = {
        ...props.campaignBudget,
        start: momentValue
      }
      props.onBudgetChange(newBudget)
    }
  }

  const onEndChange = (value: moment.Moment) => {
    if (props.onBudgetChange) {
      const momentValue = moment.utc(value.format('MM/DD/yyyy'))
      const newBudget = {
        ...props.campaignBudget,
        end: momentValue
      }
      props.onBudgetChange(newBudget)
    }
  }

  const ended = props.campaign.status === CampaignStatus.CampaignEnded || props.campaign.status === CampaignStatus.Deleted || props.campaign.status === CampaignStatus.Archived

  return (
    <React.Fragment>
      {method === BudgetMethod.Indefinite && (
        <EuiFormRow
          label={
            <EuiText size='xs' color={method !== BudgetMethod.Indefinite ? 'subdued' : 'primary'}>
              <strong>{getBudgetLabel()}</strong>
            </EuiText>
          }>
          <EuiFieldNumber fullWidth prepend={'$'} value={props.campaignBudget.budget} onChange={e => onBudgetChange(Number(e.target.value))} min={minWeeklyBudget} disabled={props.campaign?.hasBundle || !!props.campaign?.activated} />
        </EuiFormRow>
      )}
      {method == BudgetMethod.Dates && whiteLabel?.isAgencies() && (
        <React.Fragment>
          <EuiFormRow
            label={
              <EuiText size='xs' color={method !== BudgetMethod.Dates ? 'subdued' : 'primary'}>
                <strong>Start Date</strong>
              </EuiText>
            }>
            <EuiDatePicker fullWidth disabled={props.campaign.hasBundle || props.campaign.activeStatus === CampaignActiveStatus.Active || ended} selected={props.campaignBudget.start} dateFormat='MM/DD/yyyy' onChange={onStartChange} minDate={minStartDate} showTimeSelect={false} />
          </EuiFormRow>
          <EuiFormRow
            label={
              <EuiText size='xs' color={method !== BudgetMethod.Dates ? 'subdued' : 'primary'}>
                <strong>End Date</strong>
              </EuiText>
            }>
            <EuiDatePicker utcOffset={0} fullWidth disabled={props.campaign.hasBundle || props.campaign.activeStatus === CampaignActiveStatus.Active || ended} selected={props.campaignBudget.end} dateFormat='MM/DD/yyyy' onChange={onEndChange} minDate={props.campaignBudget.start ?? moment(props.campaign?.activated).add(3, 'day') ?? moment()} showTimeSelect={false} />
          </EuiFormRow>
          <EuiFormRow
            label={
              <EuiText size='xs' color={method !== 'dates' ? 'subdued' : 'primary'}>
                <strong>Total Budget (${`${minTotalBudget}`} minimum)</strong>
              </EuiText>
            }
            helpText={'Your total budget will be evenly spread across the life of your campaign.'}>
            <EuiFieldNumber step={0.01} fullWidth prepend={'$'} value={props.campaignBudget.budget} onChange={e => onBudgetChange(Number(e.target.value))} min={minTotalBudget} disabled={(!!props.campaign.activated && (props.campaignBudget.start ?? moment()) < moment()) || props.campaign?.hasBundle || ended} />
          </EuiFormRow>
        </React.Fragment>
      )}
      <EuiSpacer />
    </React.Fragment>
  )
}
