import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useFormik } from 'formik'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import LinkedInTag from 'react-linkedin-insight'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiCheckableCard, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHideFor, EuiLoadingContent, EuiShowFor, EuiSpacer, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import { EuiRadioGroupOption } from '@elastic/eui/src/components/form/radio/radio_group'

import { CampaignActiveStatus } from 'api/interfaces'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { showSuccessToast, showWarningToast } from 'app/appSlice'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { IPaymentMethodPickerState, PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'
import { AdPaymentType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { createCampaign, resetBuilder, setNameAndBudget } from './builderBillboardRetailSlice'

interface FormValues {
  campaignName: string
  budget: number
  totalBudget: number
  name: string
  phone: string
  email: string
  maxCpm: number | null
}

const ActivateStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, currentOrganization, currentUser } = useSelector((state: RootState) => state.app)
  const { campaigns } = useSelector((state: RootState) => state.campaigns)
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const organizationBundles = useGetOrganizationBundlesQuery(undefined, { skip: !currentOrganization })
  const [{ selectedPaymentMethodId, selectedCampaignSlotId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()
  const [initialValues, setInitialValues] = useState<FormValues>({
    campaignName: '',
    budget: 150,
    totalBudget: 200,
    name: '',
    phone: '',
    email: '',
    maxCpm: 3
  })
  const [showBudget, setShowBudget] = useState(true)
  const [isSavedForLater, setIsSaveForLater] = useState(false)
  const [isActivating, setIsActivating] = useState(false)
  const [isSavingForLater, setIsSavingForLater] = useState(false)
  const [method, setMethod] = useState('indefinite')
  const [start, setStart] = useState<Moment | null>(null)
  const [end, setEnd] = useState<Moment | null>(null)
  const [bidding] = useState('automatic')
  const [availableCampaignSlots, setAvailableCampaignSlots] = useState<EuiRadioGroupOption[]>([])
  const [paymentType, setPaymentType] = useState<AdPaymentType>(AdPaymentType.Standard)
  const whiteLabel = useWhiteLabel()
  const stripe = useStripe()
  const elements = useElements()
  const whitelabel = useWhiteLabel()

  const isStaff = currentUser?.roles.includes('staff') ?? false

  useEffect(() => {
    if (initialValues && initialValues.budget && initialValues.campaignName) {
      if (config.gtag.ANALYTICSID) {
        ReactGA.event('begin_checkout', { value: initialValues.budget, currency: 'USD', items: [{ item_name: 'Billboard Campaign' }], send_to: config.gtag.ANALYTICSID })
      }

      ReactPixel.track('InitiateCheckout', { value: initialValues.budget, campaign_type: 'Billboard Campaign' })
    }
  }, [initialValues.budget, initialValues.campaignName])

  useEffect(() => {
    if (currentAccount && campaigns) {
      const campaignsCount = campaigns.filter(c => c.type === 'Billboard').length
      setInitialValues({
        campaignName: builder?.name ?? `Billboard Campaign ${campaignsCount + 1}`,
        budget: builder?.budget ?? 150,
        totalBudget: builder?.totalBudget ?? 200,
        name: '',
        phone: '',
        email: '',
        maxCpm: 3
      })
    }
  }, [currentAccount, campaigns, builder])

  useEffect(() => {
    if (organizationBundles.data && !organizationBundles.isLoading && !organizationBundles.isFetching && currentUser?.roles.includes('staff')) {
      const availableCampaignSlotOptions = []
      for (let bundle of organizationBundles.data) {
        for (let slot of bundle.campaignSlots) {
          if (!slot.campaignId && slot.campaignType === CampaignType.Billboard) {
            availableCampaignSlotOptions.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
          }
        }
      }
      setAvailableCampaignSlots(availableCampaignSlotOptions)
      setPaymentType(AdPaymentType.Bundle)
    }
  }, [organizationBundles])

  useEffect(() => {
    if (builder.result) {
      if (builder.result.campaignActiveStatus === CampaignActiveStatus.Inactive) {
        if (config.gtag.ANALYTICSID) {
          ReactGA.event('add_to_cart', { value: builder.budget, currency: 'USD', send_to: config.gtag.ANALYTICSID })
        }
        if (config.gtag.ADWORDSID) {
          ReactGA.event('conversion', { transaction_id: builder.result.campaignId, send_to: 'AW-732436947/Uc2dCP3Ao_wBENOzoN0C' })
        }
        ReactPixel.track('AddToCart', { value: builder.budget, currency: 'USD', content_type: 'Billboard Campaign' })

        if (!isSavedForLater) {
          dispatch(showWarningToast('Successfully saved your campaign, but there was a problem with your payment'))
        } else {
          dispatch(showSuccessToast('Successfully saved your campaign'))
        }
        history.push(`/campaigns/edit/${builder.result.campaignId}/details`)
      }
      if (builder.result.campaignActiveStatus === CampaignActiveStatus.Active) {
        if (config.gtag.ANALYTICSID) {
          ReactGA.event('purchase', { value: builder.budget, currency: 'USD', transaction_id: builder.result.campaignId, items: [{ item_name: 'Billboard Campaign' }], send_to: config.gtag.ANALYTICSID })
        }
        if (config.gtag.ADWORDSID) {
          ReactGA.event('conversion', { transaction_id: builder.result.campaignId, send_to: 'AW-732436947/mEO9CMKO6NUBENOzoN0C' })
        }
        if (config.facebook.PIXEL_ID) {
          ReactPixel.track('Purchase', { value: builder.budget, currency: 'USD', content_type: 'Billboard Campaign' })
        }
        if (config.linkedin.PARTNER_ID && config.linkedin.PURCHASE_CONVERSION_ID) {
          LinkedInTag.track(config.linkedin.PURCHASE_CONVERSION_ID)
        }

        dispatch(showSuccessToast('Successfully activated your campaign'))
        history.push(`/build/finished/${builder.result.campaignId}/${builder.result.campaignType}`)
      }
      setIsActivating(false)
      setIsSavingForLater(false)
      dispatch(resetBuilder())
    }
  }, [dispatch, builder.result, builder.budget, currentOrganization, currentUser, isSavedForLater])

  const saveForLaterCampaign = (values: FormValues) => {
    setIsSaveForLater(true)
    setIsSavingForLater(true)

    activateCampaign(values)
  }

  const activateCampaign = (values: FormValues) => {
    if (paymentType === AdPaymentType.Bundle) {
      let budget = values.budget
      if (method === 'dates') {
        budget = values.totalBudget
      }
      let maxCpm: number | null = values.maxCpm
      if (bidding === 'automatic') {
        maxCpm = null
      }

      dispatch(createCampaign(currentAccount!.id, values.campaignName, budget, maxCpm, method === 'dates', selectedPaymentMethodId, isSavedForLater, values.name, values.phone, values.email, start, end, selectedCampaignSlotId))
    } else {
      if (!stripe || !elements) {
        return
      }
      let budget = values.budget
      if (method === 'dates') {
        budget = values.totalBudget
      }
      let maxCpm: number | null = values.maxCpm
      if (bidding === 'automatic') {
        maxCpm = null
      }

      if (isStaff && isSavedForLater) {
        dispatch(createCampaign(currentAccount!.id, values.campaignName, budget, maxCpm, method === 'dates', selectedPaymentMethodId, isSavedForLater, values.name, values.phone, values.email, start, end, null))
      } else {
        if (config.gtag.ANALYTICSID) {
          ReactGA.event('add_payment_info', { value: budget, currency: 'USD', items: [{ item_name: 'Billboard Campaign' }], send_to: config.gtag.ANALYTICSID })
        }
        ReactPixel.track('AddPaymentInfo', { value: budget, campaign_type: 'Billboard Campaign' })

        dispatch(createCampaign(currentAccount!.id, values.campaignName, budget, maxCpm, method === 'dates', selectedPaymentMethodId, isSavedForLater, values.name, values.phone, values.email, start, end, null))
      }
    }
  }

  const activateSchema = Yup.object().shape({
    campaignName: Yup.string().required('Please enter a campaign name'),
    budget: Yup.number().required('Please enter the budget').min(150, 'Please enter at least $150').typeError('Please enter a valid budget amount'),
    totalBudget: Yup.number().required('Please enter the budget').min(200, 'Please enter at least $200').typeError('Please enter a valid budget amount'),
    name: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    email: Yup.string().nullable(),
    maxCpm: Yup.number().typeError('Please enter a valid maximum CPM')
  })

  const activateCustomSchema = Yup.object().shape({
    campaignName: Yup.string().required('Please enter a campaign name'),
    budget: Yup.number().required('Please enter the budget').min(150, 'Please enter at least $150').typeError('Please enter a valid budget amount'),
    totalBudget: Yup.number().required('Please enter the budget').min(200, 'Please enter at least $200').typeError('Please enter a valid budget amount'),
    name: Yup.string().required('Please enter your name'),
    phone: Yup.string().required('Please enter your phone number'),
    email: Yup.string().nullable(),
    maxCpm: Yup.number().typeError('Please enter a valid maximum CPM')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: builder.builderStyle === 'Custom' ? activateCustomSchema : activateSchema,
    onSubmit: (values: FormValues) => {
      if (isSavedForLater) saveForLaterCampaign(values)
      else {
        setIsActivating(true)
        activateCampaign(values)
      }
    }
  })

  const gotoTarget = () => {
    history.push('/build/billboards/public/target')
  }

  const updatePaymentMethodPickerState = (newState: IPaymentMethodPickerState) => {
    if (newState.selectedCampaignSlotId && newState.selectedCampaignSlotId.length > 0) {
      setShowBudget(false)
    } else {
      setShowBudget(true)
    }

    setPaymentMethodPickerState(newState)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{builder.builderStyle === 'Custom' ? 'Submit' : 'Activate'} Your Campaign</title>
      </Helmet>
      <EuiTitle size='s'>
        <h2>{builder.builderStyle === 'Custom' ? 'Submit' : 'Activate'} Your Campaign</h2>
      </EuiTitle>
      <EuiSpacer size='m' />

      <EuiForm component='form' onSubmit={formik.handleSubmit}>
        <EuiFormRow label='Campaign Name' fullWidth isInvalid={formik.touched.campaignName && !!formik.errors.campaignName} error={formik.errors.campaignName}>
          <EuiFieldText fullWidth value={formik.values.campaignName || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('campaignName', e.target.value, true)} isInvalid={formik.touched.campaignName && !!formik.errors.campaignName} />
        </EuiFormRow>
        <EuiSpacer />

        {organizationBundles.isLoading || organizationBundles.isFetching ? (
          <EuiLoadingContent />
        ) : (
          <React.Fragment>
            {!whiteLabel?.isAgencies() && showBudget && (
              <EuiFormRow label={`Weekly Budget ($150 minimum)`} fullWidth isInvalid={formik.touched.budget && !!formik.errors.budget} error={formik.errors.budget}>
                <EuiFieldNumber fullWidth prepend={'$'} value={formik.values.budget || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('budget', Number(e.target.value), true)} isInvalid={formik.touched.budget && !!formik.errors.budget} min={150} />
              </EuiFormRow>
            )}
            {whiteLabel?.isAgencies() && (
              <React.Fragment>
                <EuiSpacer />
                <EuiCheckableCard
                  id={htmlIdGenerator()()}
                  label={<strong>Run campaign indefinitely</strong>}
                  name={'method'}
                  checked={method === 'indefinite'}
                  onChange={() => {
                    setMethod('indefinite')
                    setStart(null)
                    setEnd(null)
                  }}>
                  <div hidden={method !== 'indefinite' || !showBudget}>
                    <EuiFormRow
                      label={
                        <EuiText size='xs' color={method !== 'indefinite' ? 'subdued' : 'primary'}>
                          <strong>Weekly Budget ($150 minimum)</strong>
                        </EuiText>
                      }
                      fullWidth
                      isInvalid={formik.touched.budget && !!formik.errors.budget}
                      error={formik.errors.budget}>
                      <EuiFieldNumber fullWidth prepend={'$'} value={formik.values.budget || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('budget', Number(e.target.value), true)} isInvalid={formik.touched.budget && !!formik.errors.budget} min={150} disabled={method === 'dates'} />
                    </EuiFormRow>
                  </div>
                </EuiCheckableCard>
                <EuiSpacer size='m' />
                <EuiCheckableCard
                  id={htmlIdGenerator()()}
                  label={<strong>Run campaign within a specific date range</strong>}
                  name={'method'}
                  checked={method === 'dates'}
                  onChange={() => {
                    setMethod('dates')
                  }}>
                  <div hidden={method !== 'dates'}>
                    <EuiFormRow
                      label={
                        <EuiText size='xs' color={method !== 'dates' ? 'subdued' : 'primary'}>
                          <strong>Start Date</strong>
                        </EuiText>
                      }
                      fullWidth>
                      <EuiDatePicker fullWidth disabled={method === 'indefinite'} selected={start} dateFormat='MM/DD/yyyy' onChange={setStart} onClear={() => setStart(null)} minDate={moment().add(3, 'day')} showTimeSelect={false} />
                    </EuiFormRow>
                    <EuiFormRow
                      label={
                        <EuiText size='xs' color={method !== 'dates' ? 'subdued' : 'primary'}>
                          <strong>End Date</strong>
                        </EuiText>
                      }
                      fullWidth>
                      <EuiDatePicker fullWidth disabled={method === 'indefinite'} selected={end} dateFormat='MM/DD/yyyy' onChange={setEnd} onClear={() => setEnd(null)} minDate={start ?? moment().add(3, 'day')} showTimeSelect={false} />
                    </EuiFormRow>
                    <EuiFormRow
                      hidden={!showBudget}
                      label={
                        <EuiText size='xs' color={method !== 'dates' ? 'subdued' : 'primary'}>
                          <strong>Total Budget ($200 minimum)</strong>
                        </EuiText>
                      }
                      helpText={'Your total budget will be evenly spread across the life of your campaign.'}
                      fullWidth
                      isInvalid={formik.touched.totalBudget && !!formik.errors.totalBudget}
                      error={formik.errors.totalBudget}>
                      <EuiFieldNumber fullWidth prepend={'$'} value={formik.values.totalBudget || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('totalBudget', Number(e.target.value), true)} isInvalid={formik.touched.totalBudget && !!formik.errors.totalBudget} min={200} disabled={method === 'indefinite'} />
                    </EuiFormRow>
                  </div>
                </EuiCheckableCard>
                <EuiSpacer />
              </React.Fragment>
            )}
            {currentAccount?.id && (
              <PaymentMethodPicker
                campaignType={CampaignType.Billboard}
                paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId }}
                updatePaymentMethodPickerState={updatePaymentMethodPickerState}
                onAddPaymentMethodClicked={() => {
                  dispatch(setNameAndBudget({ name: formik.values.campaignName, budget: formik.values.budget, totalBudget: formik.values.totalBudget }))
                }}
              />
            )}
            <EuiSpacer />

            {builder.builderStyle === 'Custom' && (
              <EuiCallOut title='Submitting Your Campaign' iconType='bell'>
                <p>Upon submitting your campaign, {whitelabel?.getName()} will contact you to discuss creating your custom ads.</p>
                <p>Your card will not be charged until your campaign is finished and approved to run.</p>
              </EuiCallOut>
            )}
            {builder.builderStyle !== 'Custom' && (
              <EuiCallOut title='Activate Your Campaign' iconType='bell'>
                {availableCampaignSlots.length <= 0 && <p>Upon activating your campaign, you will be charged for {method === 'dates' ? 'the total amount of your campaign' : 'your first week of advertising'}, based on the budget you set.</p>}
                <p>Your ads will be submitted for approval with all the major ad exchanges. The approval process takes two to three business days to complete. Once approved, your ad will begin to run automatically.</p>
              </EuiCallOut>
            )}

            {builder.builderStyle === 'Custom' && (
              <React.Fragment>
                <EuiSpacer />
                <EuiTitle size='s'>
                  <h2>Customizations Contact</h2>
                </EuiTitle>
                <EuiSpacer size='m' />
                <p>Best contact to discuss your custom ads request.</p>
                <EuiSpacer size='s' />
                <div id='customContactName'>
                  <EuiFormRow label='Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
                    <EuiFieldText fullWidth value={formik.values.name || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('name', e.target.value, true)} isInvalid={formik.touched.name && !!formik.errors.name} />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                </div>
                <div id='phone'>
                  <EuiFormRow label='Phone' fullWidth isInvalid={formik.touched.phone && !!formik.errors.phone} error={formik.errors.phone}>
                    <EuiFieldText fullWidth value={formik.values.phone || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('phone', e.target.value, true)} isInvalid={formik.touched.phone && !!formik.errors.phone} />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                </div>
                <div id='email'>
                  <EuiFormRow label='Email' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} error={formik.errors.email}>
                    <EuiFieldText fullWidth value={formik.values.email || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('email', e.target.value, true)} isInvalid={formik.touched.email && !!formik.errors.email} />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <EuiSpacer />

        <EuiShowFor sizes={['xs', 's']}>
          <EuiFlexGroup>
            {builder.builderStyle !== 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton id='activate' fill iconType='check' type='submit' isLoading={isActivating} isDisabled={(!selectedPaymentMethodId && !selectedCampaignSlotId) || builder.isCreating}>
                  Activate
                </EuiButton>
              </EuiFlexItem>
            )}
            {builder.builderStyle === 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='save'
                  iconType='check'
                  iconSide='right'
                  fill
                  onClick={() => {
                    setIsSaveForLater(true)
                    formik.handleSubmit()
                  }}
                  isLoading={isSavingForLater}
                  isDisabled={builder.isCreating}>
                  Submit to Ad Team
                </EuiButton>
              </EuiFlexItem>
            )}
            {builder.builderStyle !== 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='save'
                  iconType='save'
                  iconSide='left'
                  onClick={() => {
                    setIsSaveForLater(true)
                    formik.handleSubmit()
                  }}
                  isLoading={isSavingForLater}
                  isDisabled={builder.isCreating}>
                  Save for Later
                </EuiButton>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill color='text' isDisabled={builder.isCreating} onClick={gotoTarget}>
                Back
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiShowFor>

        <EuiHideFor sizes={['xs', 's']}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' isDisabled={builder.isCreating} onClick={gotoTarget}>
                Back
              </EuiButton>
            </EuiFlexItem>
            {builder.builderStyle !== 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='save'
                  iconType='save'
                  iconSide='left'
                  onClick={() => {
                    setIsSaveForLater(true)
                    formik.handleSubmit()
                  }}
                  isLoading={isSavingForLater}
                  isDisabled={builder.isCreating}>
                  Save for Later
                </EuiButton>
              </EuiFlexItem>
            )}
            {builder.builderStyle === 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='save'
                  iconType='check'
                  iconSide='right'
                  fill
                  onClick={() => {
                    setIsSaveForLater(true)
                    formik.handleSubmit()
                  }}
                  isLoading={isSavingForLater}
                  isDisabled={builder.isCreating}>
                  Submit to Ad Team
                </EuiButton>
              </EuiFlexItem>
            )}
            {builder.builderStyle !== 'Custom' && (
              <EuiFlexItem grow={false}>
                <EuiButton id='activate' fill iconType='check' type='submit' isLoading={isActivating} isDisabled={(!selectedPaymentMethodId && !selectedCampaignSlotId) || builder.isCreating}>
                  Activate
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiHideFor>
      </EuiForm>
    </React.Fragment>
  )
}

export default ActivateStep
