import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import LinkedInTag from 'react-linkedin-insight'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import config from 'app/config'
import store from 'app/store'
import { getCurrentOrganization, getCurrentUser } from 'services/LocalStoreManagerService'

import './index.scss'
import history from './services/HistoryService'

const stripePromise = loadStripe(config.stripe.PUBLISHABLEKEY)

Bugsnag.start({
  apiKey: config.bugsnag.APIKEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.bugsnag.RELEASE_STAGE,
  enabledReleaseStages: ['production', 'staging'],
  onError: function (event) {
    const currentUser = getCurrentUser()
    const currentOrganization = getCurrentOrganization()
    if (currentUser) {
      event.setUser(currentUser.email, currentUser.email, currentUser.name)
    }
    if (currentOrganization) {
      event.addMetadata('organization', {
        name: currentOrganization.name,
        organizationId: currentOrganization.id
      })
    }
  }
})

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

if (config.gtag.ANALYTICSID && config.gtag.ADWORDSID) {
  ReactGA.initialize([{ trackingId: config.gtag.ANALYTICSID }, { trackingId: config.gtag.ADWORDSID }])
}

if (config.facebook.PIXEL_ID) {
  ReactPixel.init(config.facebook.PIXEL_ID, undefined, { autoConfig: true, debug: false })
  ReactPixel.pageView()
}

if (config.linkedin.PARTNER_ID) {
  LinkedInTag.init(config.linkedin.PARTNER_ID, 'px', false)
}

const render = () => {
  const App = require('./app/App').default

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Elements stripe={stripePromise}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Elements>
      </Router>
    </Provider>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render)
}
