import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiLoadingContent, EuiBasicTable, EuiBasicTableColumn } from '@elastic/eui'

import { Invoice } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import ViewInvoiceLink from 'components/ViewInvoiceLink'

import { fetchOrganizationInvoices } from './organizationSlice'

const InvoicesDrawer = () => {
  const dispatch = useDispatch()
  const organization = useSelector((state: RootState) => state.organization)
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    dispatch(fetchOrganizationInvoices(currentAccount!.id))
  }, [currentOrganization, dispatch])

  const convertCamelCase = (camelCaseString: string) => {
    let spaceSeparatedString = camelCaseString.replace(/([A-Z])/g, ' $1')

    if (spaceSeparatedString.charAt(0) === ' ') {
      spaceSeparatedString = spaceSeparatedString.slice(1)
    }

    return spaceSeparatedString
  }

  const columns: Array<EuiBasicTableColumn<Invoice>> = [
    {
      name: 'Date',
      field: 'issueDate',
      render: (item: string) => moment(item).format('DD MMM yyyy')
    },
    {
      name: 'Number',
      field: 'handle'
    },
    {
      name: 'Amount',
      field: 'totalAmount',
      render: (item: number) => formatter.format(item)
    },
    {
      name: 'Status',
      field: 'status',
      render: (status: string) => convertCamelCase(status)
    },
    {
      name: '',
      field: '',
      render: (invoice: Invoice) => <ViewInvoiceLink invoiceHandle={invoice.handle} />
    }
  ]

  let content

  if (organization.invoices == null || organization.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        <EuiBasicTable loading={organization.isLoading} items={organization.invoices} columns={columns} />
      </React.Fragment>
    )
  }

  return <div>{content}</div>
}

export default InvoicesDrawer
