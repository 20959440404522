import React, { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import LinkedInTag from 'react-linkedin-insight'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'

import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { TargetingType } from 'definitions/BuilderDefinitions'
import history from 'services/HistoryService'

import { BuilderStyle, setTargetingType } from './builderInternetRetailSlice'

const TargetChooseStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetRetail)

  useEffect(() => {
    if (config.gtag.ANALYTICSID) {
      ReactGA.event('select_item', { items: [{ item_name: 'Internet Campaign' }], send_to: config.gtag.ANALYTICSID })
    }

    ReactPixel.track('ViewContent', { content_type: 'Internet Campaign' })

    LinkedInTag.track(config.linkedin.VIEW_CONTENT_CONVERSION_ID)
  }, [])

  const setStandard = () => {
    dispatch(setTargetingType(TargetingType.Standard))
    history.push('/build/internet/public/target-smart')
  }

  const setLocation = () => {
    dispatch(setTargetingType(TargetingType.Advanced))
    history.push('/build/internet/public/target-location')
  }

  const setAudience = () => {
    dispatch(setTargetingType(TargetingType.Advanced))
    history.push('/build/internet/public/target-audience')
  }

  const setRetarget = () => {
    dispatch(setTargetingType(TargetingType.Advanced))
    history.push('/build/internet/public/target-retarget')
  }

  const gotoCreate = () => {
    if (builder.builderStyle === BuilderStyle.Upload) {
      history.push('/build/internet/public/upload')
    } else {
      history.push('/build/internet/public/create')
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard icon={<EuiIcon size='xxl' type='crosshairs' color='success' />} id='smartTargeterTile' title='Smart Targeter' description='Use intelligent ad targeting for your specific business type.' onClick={setStandard} footer={<EuiText size='s'>Provide a few basic details to enjoy a professionally targeted campaign.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard
            className='locationTargetingTile'
            icon={<EuiIcon size='xxl' type='pinFilled' color='success' />}
            title='Location Targeting'
            description='Target geographic areas.'
            onClick={setLocation}
            footer={
              <EuiText size='s'>
                Geo-target street addresses,
                <br /> zip codes, entire cities,
                <br />
                or a whole state.
              </EuiText>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard id='audienceTargetingTile' icon={<EuiIcon size='xxl' type='users' color='success' />} title='Audience Targeting' isDisabled={false} description='Target by specific criteria.' onClick={setAudience} footer={<EuiText size='s'>Target an audience based on interests, behaviors, vocations, brand affinities, or demographics.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard id='searchTargetingTile' icon={<EuiIcon size='xxl' type='magnet' color='success' />} title='Retargeting' isDisabled={false} description='Target your audience' onClick={setRetarget} footer={<EuiText size='s'>Target people who have seen your campaigns or have been to your website.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoCreate} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TargetChooseStep
