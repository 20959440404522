import { Organization } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const organizationsTags = platformApi.enhanceEndpoints({ addTagTypes: ['Organization', 'CanAccessAgencyPackages'] })

const organizationsApi = organizationsTags.injectEndpoints({
  endpoints: build => ({
    getOrganization: build.query<Organization, void>({
      query: () => {
        return {
          url: `organizations`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Organization' }]
    }),
    canAccessAgencyPackages: build.query<boolean, void>({
      query: () => {
        return {
          url: `organizations/canAccessAgencyPackages`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CanAccessAgencyPackages' }]
    })
  }),
  overrideExisting: false
})

export const { useCanAccessAgencyPackagesQuery, useGetOrganizationQuery } = organizationsApi
