import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiFilePicker } from '@elastic/eui'

import { apiGetInternetBuilderUpload } from 'api/adcritterApi'
import { RootState } from 'app/rootReducer'

export interface IAcFileUploader {
  maxFileSize?: number
  allowedFileTypes?: string[]
  onUploadedUrlChange: (url: string) => void
  onIsValidFileChange: (isValidFile: boolean) => void
  isLoading?: boolean
}

export const AcFileUploader: React.FC<IAcFileUploader> = props => {
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const { currentAccount } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (currentAccount) {
      apiGetInternetBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      if (props.allowedFileTypes) {
        if (!props.allowedFileTypes.includes(files[0].type)) {
          props.onIsValidFileChange(false)
          return
        }
      }
      if (props.maxFileSize) {
        if (files[0].size > props.maxFileSize) {
          props.onIsValidFileChange(false)
          return
        }
      }

      const fileNameSplit = files[0].name.split('.')
      const fileName = fileNameSplit[0]
      const fileExtension = fileNameSplit[1]

      const blockBlobClient = containerClient!.getBlockBlobClient(`${fileName}_${uuidv4()}.${fileExtension}`)!
      const url = blockBlobClient!.url.split('?')[0]
      props.onUploadedUrlChange(url)
      blockBlobClient!.uploadData(files[0], {
        concurrency: 20
      })

      props.onIsValidFileChange(true)
    } else {
      props.onUploadedUrlChange('')
    }
  }

  return (
    <React.Fragment>
      <EuiFilePicker
        ref={filePickerRemoval}
        fullWidth
        id='file'
        initialPromptText='Select or drag and drop your file'
        isLoading={props.isLoading}
        onChange={files => {
          onFileChange(files)
        }}
        display='large'
        aria-label='Select or drag and drop your file'
      />
    </React.Fragment>
  )
}
